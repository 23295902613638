import axios from 'axios';
import React, {useState, useEffect} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { Watch } from  'react-loader-spinner'
import ReactTooltip from 'react-tooltip';
import { FaInfoCircle, FaSadCry, FaAngleDoubleDown, FaAngleDoubleUp, FaPlus, FaMinus } from "react-icons/fa";
import Holidays from 'date-holidays';
import { Collapse } from 'react-collapse';

dayjs.extend(dayOfYear)

function App() {
  //Set default values
  const [apiKey, setApiKey] = useState(localStorage.getItem('ehApiKey') ? localStorage.getItem('ehApiKey') : '');
  const [start, setStart] = useState(new Date(dayjs().startOf('year').format('YYYY-MM-DD')));
  const [error, setError] = useState(null);
  const [end, setEnd] = useState(new Date());
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(false);
  const [showHolidays, setShowHolidays] = useState(false)
  const [workingDays, setWorkingDays] = useState([1, 2, 3, 4, 5]);
  const [workingTime, setWorkingTime] = useState(7.5);
  const [rememberApiKey, setRememberApiKey] = useState(false);
  const [userReport, setUserReport] = useState({
    overtimeHours: 0,
    overTime: 0,
    publicHolidays: [],
    name: '',
    occupation: '',
    fetched: false,
    selectedHolidays: {}
  })

  //Set timeout to toggle the error message
  useEffect(() => {
    if(error){
      setTimeout(() => {
        setError(null)
      }, 5000)
    }
  }, [error])

  //Calculate overtime hours when workingdays update
  useEffect(() => {
    if(userReport.fetched){
      const workdays = getWorkdays();
    
      let hours = userReport.data.time / 60 / 60 - workdays.workdays * workingTime;

      setUserReport({
        ...userReport,
        overtimeHours: getOverTimeHours(hours),
        overTime: hours
      })
    }
  }, [workingDays, workingTime])

  //Handle fetching and processing user data
  const fetchData = async () => {
    let userId = currentUser;

    if(!apiKey || !start || !end){
      setError('Fill in all the fields or else!')

      return;
    }

    if(rememberApiKey){
      localStorage.setItem('ehApiKey', apiKey);
    }

    setLoading(true);
    
    //If the user hasn't been cached fetch the data
    if(!currentUser){
      let user;

      try {
        user = await axios.get('https://api.everhour.com/users/me', {
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey
          }
        })
      } catch (error) {
        setLoading(false);

        setError('Something went wrong trying to fetch your information :(')

        return;
      }

      setCurrentUser(user.data.id)
      
      userId = user.data.id;
    }

    let report;

    //Fetch the user time data
    try {
      report = await axios.get('https://api.everhour.com/dashboards/users', {
        params: {
          memberId: userId,
          date_gte: dayjs(start).format('YYYY-MM-DD'),
          date_lte: dayjs(end).format('YYYY-MM-DD')
        },
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': apiKey
        }
      })
    } catch (error) {
      setLoading(false);

      setError('Something went wrong trying to fetch your time reports :(')

      return;
    }

    if(!report.data.length){
      setError('The API returned nothing which is probably your fault >:(')
    }

    const data = report.data[0];

    //Calculate working days during the selected period
    const workdays = getWorkdays();
    
    let hours = data.time / 60 / 60 - workdays.workdays * workingTime;

    //Set the complete report
    setUserReport({
      overtimeHours: getOverTimeHours(hours),
      overTime: hours,
      publicHolidays: workdays.holidays,
      data: data,
      fetched: true,
      selectedHolidays: {}
    })

    setLoading(false);
  }

  //Return the working days for the selected time period
  const getWorkdays = () => {
    const formattedStart = dayjs(start)
    const formattedEnd = dayjs(end);
    const holiday = new Holidays('FI')
    const publicHolidays = [];

    let workdays = 0;

    for(let i = 0; i < formattedEnd.diff(formattedStart, 'day') + 1; i++){
      const currentDay = dayjs(start).add(i, 'day');
      const weekDay = currentDay.day();
      const isHoliday = holiday.isHoliday(currentDay.toDate());

      if(isHoliday){
        publicHolidays.push(isHoliday[0]);
      }

      workdays = !workingDays.includes(weekDay) ? workdays : workdays + 1;
    }

    return {workdays: workdays, holidays: publicHolidays};
  }

  //Calculate over(under)time hours
  const getOverTimeHours = (hours) => {
    let overTime = (hours.toFixed(2)).split('.');

    return overTime[0] + ' h ' + Math.round(overTime[1] * 0.6) + ' min';
  }

  //Handle holiday toggle
  const toggleHoliday = (date, action) => {
    let hours = userReport.overTime
    let selectedHolidays = {
      ...userReport.selectedHolidays
    }

    if(selectedHolidays[date]){
      delete selectedHolidays[date];
    }
    else{
      selectedHolidays[date] = action;
    }

    hours = hours + (action == 'add' ? + workingTime : - workingTime);

    setUserReport({
      ...userReport,
      selectedHolidays: selectedHolidays,
      overtimeHours: getOverTimeHours(hours),
      overTime: hours
    })
  }

  //Handle toggling the working days
  const toggleWorkingDay = (e) => {
    const currentValue = parseInt(e.target.value);

    setWorkingDays(workingDays.includes(currentValue) ? workingDays.filter(w => w != currentValue) : [...workingDays, currentValue]);
  }

  return (
    <div className="container">
      <h2 data-tip data-for='titleTip' style={{marginBottom: "2rem"}}>
        Everhour time calculator! 
        <FaInfoCircle style={{marginLeft: "1rem", fontSize: "1.5rem"}}/>
      </h2>
      <ReactTooltip id="titleTip" place="top" type="dark" effect="float">
        <p style={{maxWidth: "400px", margin: 0}}>You'll find your API key from your Everhour settings. This application does NOT save any of your information and it's just a simple React app!</p>
      </ReactTooltip>
      {loading ? <div className='loader'>
        <Watch color="#3d3d3d" height={80} width={80} />
      </div> : null}
      <div className="form-group">
        <label>API key</label>
        <input name="apiKey" value={apiKey} className="form-control" onChange={(e) => setApiKey(e.target.value)}/>
      </div>
      <div className="form-check mb-3">
        <input type="checkbox" name="rememberApiKey" className="form-check-input" onChange={(e) => setRememberApiKey(!rememberApiKey)} checked={rememberApiKey}/>
        <label data-tip data-for='apiKeyTip' className='form-check-label'>Remember API key<FaInfoCircle style={{marginLeft: ".5rem"}}/></label>
      </div>
      <ReactTooltip id="apiKeyTip" place="top" type="dark" effect="float">
        <span>The api key will be stored in your browser's cache</span>
      </ReactTooltip>
      <div className="form-row">
        <div className="form-group col">
          <label>Start date</label>
          <DatePicker className="form-control" name="startDate" selected={start} onChange={(date) => setStart(date)} dateFormat={'yyyy-MM-dd'}/>
        </div>
        <div className="form-group col">
          <label>End date</label>
          <DatePicker className="form-control" name="endDate" selected={end} onChange={(date) => setEnd(date)} dateFormat={'yyyy-MM-dd'}/>
        </div>
      </div>
      <div className="form-group">
        <label>Working days</label>
        <div className="mb-4 weekday-wrapper">
          <button type="button" value={1} onClick={toggleWorkingDay} className={'weekday-button' + (workingDays.includes(1) ? ' active' : '')}>Mon</button>
          <button type="button" value={2} onClick={toggleWorkingDay} className={'weekday-button' + (workingDays.includes(2) ? ' active' : '')}>Tue</button>
          <button type="button" value={3} onClick={toggleWorkingDay} className={'weekday-button' + (workingDays.includes(3) ? ' active' : '')}>Wed</button>
          <button type="button" value={4} onClick={toggleWorkingDay} className={'weekday-button' + (workingDays.includes(4) ? ' active' : '')}>Thu</button>
          <button type="button" value={5} onClick={toggleWorkingDay} className={'weekday-button' + (workingDays.includes(5) ? ' active' : '')}>Fri</button>
          <button type="button" value={6} onClick={toggleWorkingDay} className={'weekday-button' + (workingDays.includes(6) ? ' active' : '')}>Sat</button>
          <button type="button" value={0} onClick={toggleWorkingDay} className={'weekday-button' + (workingDays.includes(0) ? ' active' : '')}>Sun</button>
        </div>
      </div>
      <div className="form-group">
        <label>Working time / day</label>
        <input type="number" step="0.1" name="workingTime" value={workingTime} className="form-control" onChange={(e) => setWorkingTime(e.target.value ? e.target.value : 0)}/>
      </div>
      {error ? <div className="alert alert-danger" role="alert">
        {error}
      </div> : null}
      <div className="form-group">
        <button type="submit" className="btn btn-custom w-100" onClick={fetchData}>Submit</button>
      </div>
      <hr/>
      {userReport.fetched && !loading ? 
      <div className="row text-center">
        <div className="col-12">
          <div className={'alert' + (userReport.overTime > 0 ? ' alert-success' : ' alert-danger')}>
            <h2 data-tip data-for='infoTip'>
              {userReport.overtimeHours}
              {userReport.overTime > 0 ? 
                <FaInfoCircle style={{marginLeft: "1rem", fontSize: "1rem"}}/> : 
                <FaSadCry style={{marginLeft: "1rem", fontSize: "1rem"}} />
              }
            </h2>
            <ReactTooltip id="infoTip" place="top" type="dark" effect="float">
              <span>May or may not include all paid and unpaid vacation days.</span>
            </ReactTooltip>
          </div>
        </div>
        <div className="col-12" style={{marginBottom: "1rem"}}>
          <div className='holidays'>
            <a href="#" type="submit" className="w-100" onClick={() => setShowHolidays(!showHolidays)}>
             {showHolidays ? <FaAngleDoubleUp/> : <FaAngleDoubleDown/>} Advanced settings {showHolidays ? <FaAngleDoubleUp/> : <FaAngleDoubleDown/>}
            </a>
            <Collapse isOpened={showHolidays}>
              <label>Public holidays between the selected dates</label>
              <div className='row'>
                {userReport.publicHolidays.map((h, key) => 
                  <div className='col-3' key={key} style={{marginBottom: "1rem"}}>
                    <div className={"holiday-box"}>
                      <p>{dayjs(h.date).format('YYYY-MM-DD')}</p>
                      <p style={{fontSize: "1.5rem"}}>{h.name}</p>
                      <a href="#" 
                        className={'holiday-button' + (userReport.selectedHolidays[h.date] ? (userReport.selectedHolidays[h.date] === 'remove' ? ' active' : ' inactive') : '')}
                        onClick={() => toggleHoliday(h.date, 'add')}
                      >
                        <FaPlus/>
                      </a>
                      <a href="#" 
                        className={'holiday-button' + (userReport.selectedHolidays[h.date] ? (userReport.selectedHolidays[h.date] === 'add' ? ' active' : ' inactive') : '')}
                        onClick={() => toggleHoliday(h.date, 'remove')}
                      >
                        <FaMinus/>
                      </a>
                    </div>
                  </div>
                )}
                {!userReport.publicHolidays.length ? <div className='col-12 text-center my-3'>No public holidays</div> : null}
              </div>
            </Collapse>
          </div>
        </div>
        <div className="col-md-6 col-xs-12 mb-4">
          <div className='info-box'>
            <h3 className='mb-4'>Name</h3>
            <p>{userReport.data.memberName}</p>
          </div>
        </div>
        <div className="col-md-6 col-xs-12 mb-4">
          <div className='info-box'>
            <h3 className='mb-4'>Title</h3>
            <p>{userReport.data.memberHeadline}</p>
          </div>
        </div>
      </div> : null}
    </div>
  );
}

export default App;
